<template>
  <div class="indexReview">
    <div class="indexReview__header container">
      <h2 class="indexReview__header-title">
        Projdi si online kurzy zdarma<br>
        a získej novou práci jako naši absolventi.
      </h2>
    </div>
    <div class="indexReview__body">
      <IndexReviewCarousel/>
    </div>
    <div class="indexReview__footer container">
      <BaseButton
          v-if="!loggedIn"
          :is-link="true"
          :href="'/registration'"
      >
        Zaregistruj se zdarma
      </BaseButton>
      <BaseButton
          v-else
          :is-link="true"
          :href="'/courses'"
      >
        Chci studovat
      </BaseButton>
    </div>
  </div>
</template>
<script>
import BaseButton from "@/components/BaseButton";
import IndexReviewCarousel from "@/components/IndexReviewCarousel";
import {mapGetters} from "vuex";

export default {
  components: {IndexReviewCarousel, BaseButton},
  computed: {
    ...mapGetters("user", ["loggedIn"]),
  },
}
</script>