<template>
  <div class="indexCounters">
    <div class="container">
      <div class="indexCounters__header">
        <h2 class="h1 indexCounters__header-title">
          Uč se od profesionálů a&nbsp;rozjeď kariéru v IT! 
        </h2>
      </div>
      <div class="indexCounters__body">
        <AppCounters :variant="'index'"/>
      </div>
    </div>
    <img
        src="../assets/images/particles/laptop.svg"
        alt=""
        class="indexCounters__particle indexCounters__particle--0"
        width="348"
        height="290"
        loading="lazy"
    >
    <img
        src="../assets/images/particles/certificate.svg"
        alt=""
        class="indexCounters__particle indexCounters__particle--1"
        width="315"
        height="267"
        loading="lazy"
    >
  </div>
</template>

<script>
import AppCounters from "@/components/AppCounters";

export default {
  components: {
    AppCounters,
  }
}

</script>