import {authorizationService} from "@/services/authorization";
import {codebooksService} from "@/services/codebooks.service";
import {createApp, h} from 'vue'
import './assets/scss/index.scss'
import App from './pages/index.vue'
import store from './store'

createApp({
    created() {
        authorizationService.checkAccess('index')
        const user = this.$store.dispatch('user/getUser')
        this.$store.dispatch('studyPrograms/fetchData')
        codebooksService.getAllCodebooks()
        sessionStorage.setItem('lastPage', '/');
    },
    render: () => h(App),
})
    .use(store)
    .mount('#app')
