<template>
  <picture>
    <source
        type="image/webp"
        :srcset="
          require(`../assets/images/indexHero/indexHeroCarousel/${image}_50.webp`) + ` ${Math.round(width/2)}w,` +
          require(`../assets/images/indexHero/indexHeroCarousel/${image}.webp`) + ` ${width}w`
        "
        sizes="(min-width: 768px) 500px, 80vw"
    >
    <img
        class="indexHeroCarousel__item"
        :class="[activeItem === id ? 'is-active' : null, `indexHeroCarousel__item--${id}`]"
        alt=""
        :src="require(`../assets/images/indexHero/indexHeroCarousel/${image}_50.png`)"
        :srcset="
          require(`../assets/images/indexHero/indexHeroCarousel/${image}_50.png`) + ` ${Math.round(width/2)}w,` +
          require(`../assets/images/indexHero/indexHeroCarousel/${image}.png`) + ` ${width}w`
        "
        sizes="(min-width: 768px) 500px, 80vw"
        :width="width"
        :height="height"
        :loading="lazy ? 'lazy' : null"
        :rel="preload ? 'preload' : null"
        decoding="async"
    >
  </picture>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    activeItem: {
      type: Number,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    lazy: {
      type: Boolean,
      default: true
    },
    preload: {
      type: Boolean,
      default: true
    },
  }
}
</script>