<template>
  <div class="indexInfo">
    <div class="container container--sm">
      <div class="indexInfo__header">
        <h2 class="h1 indexInfo__header-title">
          Základní informace
        </h2>
      </div>
      <div class="indexInfo__body">
        <IndexCollapse/>
      </div>
      <div class="indexInfo__footer">
        <BaseButton
          v-if="!loggedIn"
          :is-link="true"
          :href="'/registration'"
      >
        Zaregistruj se zdarma
      </BaseButton>
      <BaseButton
          v-else
          :is-link="true"
          :href="'/courses'"
      >
        Chci studovat
      </BaseButton>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from "@/components/BaseButton";
import IndexCollapse from "@/components/IndexCollapse";
import {mapGetters} from "vuex";

export default {
  components: {IndexCollapse, BaseButton},
  computed: {
    ...mapGetters("user", ["loggedIn"]),
  },
}
</script>