<template>
  <div class="indexJobs">
    <div class="container container--md">
      <h2 class="h1 indexJobs__title mb-3">
        Volné IT&nbsp;pozice
      </h2>
      <p>
        Už brzy až 100 volných pracovních míst! Svez se na vlně popularity informačních technologií! My&nbsp;ti&nbsp;zdarma poskytneme rekvalifikaci a ještě ti najdeme novou práci!
      </p>
      <BaseButton
          :is-link="true"
          :href="'https://jobs.datacruit.com/nabidky-prace/itjede/'"
          target="_blank"
      >
        Ukázat pozice
      </BaseButton>
    </div>
    <img
        src="../assets/images/particles/briefcase.svg"
        alt=""
        class="indexJobs__particle"
        width="430"
        height="401"
        loading="lazy"
    >
  </div>
</template>
<script>
import BaseButton from "@/components/BaseButton";

export default {
  components: {BaseButton}
}
</script>
