<template>
  <div class="indexAbout">
    <div class="container container--xs">
      <div class="indexAbout__header">
        <h2 class="h1 indexAbout__header-title">
          Jaké kurzy tě čekají?
        </h2>
      </div>
      <div class="indexAbout__body">
        <p>
          Vybírat můžeš ze široké nabídky kurzů zaměřených na <mark>cloudové technologie</mark>, jako jsou produkty z rodiny <mark>Microsoft 365</mark>, <mark>Azure</mark> nebo <mark>Power Platform</mark>. Dozvíš se o základních pojmech v úvodu do <mark>AI a strojového učení</mark>. V nabídce najdeš také oblast <mark>zabezpečení dat a cybersecurity</mark>. A pokud chceš proniknout do světa <mark>CRM a ERP systémů</mark>, tak i z této oblasti zde najdeš zajímavý obsah.        </p>
        <p>
          Pokud nevíš, kde začít, doporučujeme využít naše <mark>studijní programy</mark>, které tě postupně provedou jednotlivými technologiemi. Tyto programy jsou navrženy tak, aby ses mohl(a) učit v logickém sledu a nic důležitého ti neuniklo.         </p>
      </div>
      <div class="indexAbout__footer">
        <BaseButton
            v-if="!loggedIn"
            :is-link="true"
            :href="'/registration'"
        >
          Zaregistruj se
        </BaseButton>
        <BaseButton
            v-else
            :is-link="true"
            :href="'/courses'"
        >
          Chci studovat
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import {mapGetters} from "vuex";

export default {
  components: {
    BaseButton,
  },
  computed: {
    ...mapGetters("user", ["loggedIn"]),
  },
}
</script>