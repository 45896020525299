<template>
  <div class="indexHeroCarousel">
      <svg width="501" height="438" viewBox="0 0 501 438" xmlns="http://www.w3.org/2000/svg">
        <path d="M361.704 75.234c39.72 25.76 84.073 48.22 110.552 86.53 27.142 38.972 36.41 93.136 21.184 139.374-15.888 46.237-56.269 83.888-100.622 107.007-44.354 22.458-92.68 31.045-139.68 29.724-47.664-1.321-94.003-12.55-139.68-35.008C67.78 379.74 23.427 346.054 7.539 301.138c-16.55-44.256-3.31-99.08 15.226-151.263C40.639 97.692 65.132 48.812 104.19 23.05 143.247-2.709 197.53-4.69 242.546 8.52c44.353 13.21 79.439 41.614 119.158 66.714Z" fill="#F7C32E" fill-rule="nonzero"/>
      </svg>
      <IndexHeroCarouselItem
          v-for="(item, index) in images"
          :id="index"
          :activeItem="current"
          :image="item.src"
          :width="item.width"
          :height="item.height"
          :lazy="index !== 0"
          :preload="index === 0"
      />
      <IndexHeroParticle
          v-for="item in particles"
          :key="item.id"
          :id="item.id"
          :width="item.width"
          :height="item.height"
      />
  </div>
</template>

<script>
import IndexHeroCarouselItem from "@/components/IndexHeroCarouselItem";
import IndexHeroParticle from "@/components/IndexHeroParticle";

export default {
  data() {
    return {
      min: 0,
      max: 1,
      interval: 5000,
      current: 0,
      images: [
        {
          src: '0',
          width: 795,
          height: 782,
        },
        {
          src: '1',
          width: 734,
          height: 765,
        }
      ],
      particles: [
        {
          id: 'microsoft-azure',
          width: 97,
          height: 29,
        },
        {
          id: 'microsoft-365',
          width: 118,
          height: 20,
        },
        {
          id: 'star',
          width: 40,
          height: 39,
        },
      ]
    }
  },
  created() {
    // run interval
    setInterval(() => {
      this.cycleImages();
    }, this.interval)
  },
  methods: {
    // change current image
    cycleImages() {
      this.current++;
      if (this.current > this.max) {
        this.current = this.min;
      }
    }
  },
  components: {
    IndexHeroParticle,
    IndexHeroCarouselItem
  },
}
</script>