<template>
  <div
      class="indexHeroParticle"
      :class="`indexHeroParticle--${id}`"
  >
    <img
        :src="require(`../assets/images/indexHero/indexHeroParticle/${id}.svg`)"
        alt=""
        :width="width"
        :height="height"
        loading="lazy"
    >
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
  }
}
</script>