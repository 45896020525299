<template>
    <div :class="{'instructors': true, 'instructors--dark': dark}">
        <h2>Náš team</h2>
        <InstructorsLayout :items-count="3">
          <InstructorCard
            image="pavel-vanura.jpeg"
            name="Pavel Vaňura"
            position="Talent Acquisition Advisor"
            text="Seznam se s Pavlem, který s tebou bude řešit tvé kariérní možnosti. Pavel původně vystudoval IT obor, ale uvědomil si, že by ho spíše naplňovala práce a komunikace s lidmi a pocit, že jim může pomoct s hledáním zaměstnání. Takže po mnohaleté zkušenosti v náboru a HR se vrhnul do spolupráce s ITjede, kde může skloubit svou dosavadní praxi a poradit lidem, na jejichž místě byl před 12 lety on sám. Neboj se na něj s čímkoliv obrátit. Rád ti poradí. Ve volném čase Pavel s oblibou cestuje, užívá si posezení s přáteli nebo se odreaguje u nějaké hry."
            socialUrl="https://www.linkedin.com/in/pavel-va%C5%88ura-8734b0237/"
            :light="dark"
          />
          <InstructorCard
            image="ondrej-hanus.jpg"
            name="Ondřej Hanuš"
            position="Chief Digital Officer"
            text="Ondra je nadšenec do digitálních dovedností a má mnoholeté zkušenosti z IT společností. Je spoluautorem a zakladatelem projektu ITjede, který aktivně posouvá dál, hledá nové cesty, které vám pomohou ve studiu nebo hledání nových kariérních uplatnění. Ondra si za svou kariéru prošel cestu od programátora až k vedoucím pozicím, takže má velmi dobré předpoklady pomoc s nasměrováním tvojí kariéry a předání zkušeností. Vedle projektu ITjede je zodpovědný za digitální strategii a inovace ve společnosti GoodCall. A mimo jiné, je to milovník dobré kávy a gastronomie :-)"
            socialUrl="https://www.linkedin.com/in/ohanus/"
            :light="dark"
          />
          <InstructorCard
            image="adam-libra.jpg"
            name="Adam Libra"
            position="Vývojář platformy, Lektor"
            text="Adam je nejmladším členem našeho teamu. Už od 16let aktivně působí na poli IT, začínal ve studentském programu od Microsoftu. Do Microsoftu následně nastoupil jako zaměstnanec. Adam je programátor s obchodím duchem a cloudové technologie jsou jeho koníčkem. V ITjede je nejen moderátorem a lektorem, ale i hlavním vývojářem celé platformy. Když zrovna není ponořen v jedničkách a nulách, rád se projede na kole a dobře nají."
            socialUrl="https://www.linkedin.com/in/adamlibra/"
            :light="dark"
          />
        </InstructorsLayout>
    </div>
</template>
<script>
    import InstructorCard from "@/components/InstructorCard.vue";
    import InstructorsLayout from "@/components/InstructorsLayout.vue";

    export default {
        props: {
            dark: {
                type: Boolean,
                default: false
            },
        },
        components: {
          InstructorCard,
          InstructorsLayout,
        }
    }
</script>