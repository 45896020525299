<template>
  <div class="indexCollapse">
    <BaseCollapse
        v-for="(item, index) in data"
        :key="item"
        :id="index"
        :isActive="activeItem === index"
        :type="'whiteShadow'"
        @baseCollapseToggle="handleClick(index)"
    >
      <template v-slot:title>{{ item.title }}</template>
      <template v-slot:default>
        <div v-html="item.text"></div>
      </template>
    </BaseCollapse>
  </div>
</template>
<script>
import BaseCollapse from "@/components/baseCollapse";

export default {
  components: {BaseCollapse},
  data() {
    return {
      activeItem: null,
      data: [
        {
          title: 'Co je ITjede.cz?',
          text: '<p>ITjede.cz je odrazový můstek pro všechny, kteří to myslí s rekvalifikací vážně. Na této platformě si rozšíříš povědomí o jednotlivých technologiích v IT světě. Nalezneš zde hned několik možných studijních programů (rozuměj cest). Zajímáš se o IT bezpečnost, pak je zde program pro roli bezpečnostního analytika. Nebo se zajímáš o oblast řízení vztahů se zákazníky, pak je zde program pro roli CRM konzultanta. Pokud ti nevyhovuje žádný z dostupných programů, pak jsou zde jednotlivé kurzy. Vše je k dispozici v podobě online videí a cílem je, seznámit se s jednotlivými koncepty dané technologie. </p><p>V neposlední řadě je zde karierní poradenství. Po absolvování jisté části obsahu se s tebou propojí jeden z našich kariérních konzultantů a pomůže ti nasměrovat tím správným směrem. Řekne ti, jak se dále vzdělávat a případně tě doporučí některému z potenciálních nových zaměstnavatelů.</p><p>Obsah je vytvářen s přímou spoluprací se společností Microsoft, díky čemuž se můžeme zaručit za kvalitu vzdělání. </p>',
        },
        {
          title: 'Komu je platforma určená?',
          text: '<p>Každému, kdo chce udělat krok kupředu ve své kariéře! Naše vzdělávací a kariérní platforma je určena jak pro úplné začátečníky, kteří chtějí získat základní znalosti a přehled o IT světě, tak pro zkušenější studenty, mající zájem o svět technologií. Absolvování kurzů ITjede ti umožní zvýšit svou hodnotu na pracovním trhu a získat kariérní příležitosti ve světě informačních technologií.</p><p>Pro začátek nepotřebuješ nic víc, než chuť a čas se vzdělávat. Proto už neváhej ani minutu – lepší práce čeká i na tebe, protože ITjede. Tak se svez taky! </p>',
        },
        {
          title: 'Jak studium probíhá?',
          text: '<p>Jakmile se zaregistruješ, získáš automatický přístup k úvodnímu kurzu „Úvodní kurz – technologie a jejich budoucnost“. Ten tě provede prostředím Microsoft technologií a systémů a tématem digitalizace firem. Dále se budeš muset rozhodnout, který ze studijních programů by tě zajímal nejvíce a kde začneš se svým vzděláváním.</p><p>Po zvolení studijního programu už můžeš začít konzumovat obsah ve formě online videí pěkně z pohodlí domova.</p>',
        },
        {
          title: 'Hledáš práci?',
          text: '<p>Po shlédnutí obsahu alespoň jednoho ze studijních programů se s tebou propojí jeden z našich karierních konzultantů, který s tebou probere tvá očekávání a tvé možnosti další rekvalifikace. Poradí ti, jak a kde se dále vzdělávat a pokud budeš připraven, vydat se na cestu za tvým vysněným zaměstnáním, pošle tvůj profil potenciálním zaměstnavatelům.  </p>',
        },
      ]
    }
  },
  methods: {
    handleClick(id) {
      if (this.activeItem === id) {
        this.activeItem = null
      } else {
        this.activeItem = id;
      }
    }
  }
}
</script>